import moment from '@/helpers/useMoment'

export const getTimeByMS = (ms = 0) => {
  const duration = moment.duration(ms)
  return {
    negative: ms < 0,
    hours: Math.abs(Math.trunc(duration.asHours())),
    minutes: Math.abs(duration.minutes()),
  }
}

export const formatTimeByMS = (ms = 0) => {
  const time = getTimeByMS(ms)
  return `${time.negative ? '-' : ''}${time.hours}h/${time.minutes}m`
}
