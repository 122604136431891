<template>
  <div v-if="estimate" class="blank-estimate">
    <div
      v-if="$get(estimate, 'inputs.status')"
      class="ribbon"
      style="display: none"
    >
      <div
        class="ribbon__inner"
        :class="getClassByStatus(estimate.inputs.status)"
      >
        <span class="g-text-uppercase">{{ estimate.inputs.status }}</span>
      </div>
    </div>
    <IncludesForBlanks />
    <div class="blank-estimate__content">
      <table class="blank-estimate__head">
        <tbody>
          <tr>
            <td style="width: 25%">
              <!--              <img-->
              <!--                :src="companyLogo"-->
              <!--                width="130"-->
              <!--                height="40"-->
              <!--                :alt="companyName"-->
              <!--                class="blank-estimate__logo"-->
              <!--              />-->
              <div
                :style="{
                  backgroundImage: `url('${companyLogo}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }"
                class="blank-estimate__logo"
              ></div>
            </td>
            <td class="text" v-html="companyInfo"></td>
            <td>
              <div class="blank-estimate__name">Estimate</div>
              <div class="blank-estimate__number">
                # {{ $get(estimate.inputs, 'number') }}
              </div>
              <!--            <div style="font-weight: bold; color: inherit"-->
              <!--                 class="blank-estimate__number">Total: {{ estimate.inputs.total | dollarFormat }}</div>-->
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blank-estimate__main">
        <div class="blank-estimate__info">
          <table class="blank-estimate__info">
            <tbody>
              <tr>
                <td style="padding: 0">
                  <div class="blank-estimate__info-list-title">Bill To</div>
                  <div class="blank-estimate__info-list">
                    <div
                      class="text"
                      v-html="
                        $get(
                          estimate.inputs,
                          'customer.attributes.information_for_documents.value'
                        )
                      "
                    ></div>
                  </div>
                </td>
                <td style="width: 200px; padding: 0">
                  <div
                    style="
                      font-weight: bold;
                      font-size: 14px;
                      text-align: right;
                      margin-bottom: 2rem;
                    "
                  >
                    Total: {{ estimate.inputs.total | dollarFormat }}
                  </div>
                  <table class="blank-estimate__info-table">
                    <tr>
                      <td>Date Created:</td>
                      <td>
                        {{
                          $get(estimate.inputs.dates, 'estimate_date')
                            | dateFormat
                        }}
                      </td>
                    </tr>
                    <tr v-if="estimate.inputs.term">
                      <td>Terms:</td>
                      <td>{{ $get(estimate.inputs.term, 'name') }}</td>
                    </tr>
                    <tr>
                      <td>Due Date:</td>
                      <td>
                        {{
                          $get(estimate.inputs.dates, 'expiration_date')
                            | dateFormat
                        }}
                      </td>
                    </tr>
                    <template
                      v-if="$get(estimate, 'inputs.type.attributes.id')"
                    >
                      <tr v-if="$get(estimate, 'inputs.related_to')">
                        <td
                          v-if="
                            $get(estimate, 'inputs.type.attributes.id') ===
                            RELATED_TO_SHIPPING_FILE_ID
                          "
                        >
                          Shipping file #:
                        </td>
                        <td
                          v-else-if="
                            $get(estimate, 'inputs.type.attributes.id') ===
                            RELATED_TO_PROJECT_ID
                          "
                        >
                          Project #:
                        </td>
                        <td>
                          <template
                            v-if="
                              $get(estimate, 'inputs.type.attributes.id') ===
                              RELATED_TO_SHIPPING_FILE_ID
                            "
                          >
                            <router-link
                              v-if="$get(estimate, 'inputs.related_to.id')"
                              :to="{
                                name: 'shipping-file',
                                params: {
                                  fileId: $get(
                                    estimate,
                                    'inputs.related_to.id'
                                  ),
                                },
                              }"
                              custom
                              v-slot="{ href }"
                            >
                              <a
                                :href="href"
                                @click.prevent="
                                  $vModal.open('file-show', {
                                    fileNumber:
                                      estimate.inputs.related_to.number,
                                    shippingFileId:
                                      estimate.inputs.related_to.id,
                                    callback: () => {
                                      $emit('refresh')
                                    },
                                  })
                                "
                                >{{
                                  $get(estimate, 'inputs.related_to.number')
                                }}</a
                              >
                            </router-link>
                            <span v-else>-</span>
                          </template>
                          <template
                            v-else-if="
                              $get(estimate, 'inputs.type.attributes.id') ===
                              RELATED_TO_PROJECT_ID
                            "
                          >
                            <router-link
                              v-if="$get(estimate, 'inputs.related_to.id')"
                              :to="{
                                name: 'project',
                                params: {
                                  projectId: $get(
                                    estimate,
                                    'inputs.related_to.id'
                                  ),
                                  status: estimate?.inputs?.related_to?.status,
                                },
                              }"
                              custom
                              v-slot="{ href }"
                            >
                              <a
                                :href="href"
                                @click.prevent="
                                  $vModal.open('project-show', {
                                    number:
                                      estimate.inputs.related_to.project_number,
                                    id: estimate.inputs.related_to.id,
                                    callback: () => {
                                      $emit('refresh')
                                    },
                                  })
                                "
                                >{{
                                  $get(
                                    estimate,
                                    'inputs.related_to.project_number'
                                  )
                                }}</a
                              >
                            </router-link>
                            <span v-else>-</span>
                          </template>
                        </td>
                      </tr>
                      <template
                        v-if="
                          $get(estimate, 'inputs.type.attributes.id') ===
                          RELATED_TO_SHIPPING_FILE_ID
                        "
                      >
                        <tr
                          v-if="
                            $get(
                              estimate,
                              'inputs.related_to.booking.inputs.booking_number'
                            )
                          "
                        >
                          <td>Booking #:</td>
                          <td>
                            <router-link
                              :to="{
                                name: 'booking',
                                params: {
                                  bookingId: $get(
                                    estimate,
                                    'inputs.related_to.booking.id'
                                  ),
                                },
                              }"
                              custom
                              v-slot="{ href }"
                            >
                              <a
                                :href="href"
                                @click.prevent="
                                  $vModal.open('booking', {
                                    id: estimate.inputs.related_to.booking.id,
                                    callback: (booking) => {
                                      $emit('refresh', booking)
                                    },
                                  })
                                "
                                >{{
                                  estimate.inputs.related_to.booking.inputs
                                    .booking_number
                                }}</a
                              >
                            </router-link>
                          </td>
                        </tr>
                        <tr v-if="containerNumbers.length">
                          <td>Container #:</td>
                          <td>
                            {{ containerNumbers.join(', ') }}
                          </td>
                        </tr>
                      </template>
                    </template>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="blank-estimate__items-wrap">
          <div class="blank-estimate__items-title">Estimate items</div>
          <table class="blank-estimate__items-table">
            <thead>
              <tr>
                <th>Item</th>
                <th v-if="isTaxForm">Quantity / Rate</th>
                <th v-if="isTaxForm">Tax</th>
                <th>Description</th>
                <th>Amount, $</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in $get(estimate.inputs, 'items')"
                :key="index"
              >
                <td>{{ item.name }}</td>
                <td v-if="isTaxForm">
                  {{ item.quantity }} <span class="g-c-grey">/</span>
                  {{ item.rate | dollarFormat }}
                </td>
                <td v-if="isTaxForm">
                  <div v-if="item.tax">
                    {{ item.tax.name }}
                    (<span v-if="item.tax.value_type === '$'">{{
                      item.tax.value | dollarFormat
                    }}</span>
                    <span v-else-if="item.tax.value_type === '%'"
                      >{{ item.tax.value }}%</span
                    >
                    <span v-else>{{ item.tax.value }}</span
                    >)
                  </div>
                </td>
                <td>
                  <div v-html="item.description" class="text"></div>
                </td>
                <td>{{ item.amount | dollarFormat }}</td>
              </tr>
            </tbody>
          </table>
          <table
            style="margin-top: 2rem"
            class="blank-estimate__items-table _subtotals"
          >
            <tbody>
              <tr style="font-weight: bold">
                <td>Subtotal:</td>
                <td></td>
                <td>{{ estimate.inputs.subtotal | dollarFormat }}</td>
              </tr>
              <tr v-if="estimate.inputs.adjustments">
                <td>{{ estimate.inputs.adjustments_name || 'Adjustments' }}</td>
                <td></td>
                <td>
                  <span>{{ estimate.inputs.adjustments | dollarFormat }}</span>
                </td>
              </tr>
              <tr
                v-for="(subtotal, index) in estimate.inputs.subtotal_details"
                :key="index"
              >
                <td>{{ subtotal.name }}</td>
                <td>{{ subtotal.type }}</td>
                <td>
                  <span v-if="subtotal.value_type === '$'">{{
                    subtotal.value | dollarFormat
                  }}</span>
                  <span v-else-if="subtotal.value_type === '%'"
                    >{{ subtotal.value }}%</span
                  >
                  <span v-else>{{ subtotal.value }}</span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td
                  style="
                    background-color: #f2f2f2;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  Total:
                </td>
                <td style="background-color: #f2f2f2"></td>
                <td
                  style="
                    background-color: #f2f2f2;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  {{ estimate.inputs.total | dollarFormat }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          v-if="estimate.notes || estimate.terms_and_conditions"
          style="margin-top: 3rem"
          class="blank-estimate__info"
        >
          <template v-if="estimate.notes.length">
            <div class="blank-estimate__info-list-title">Notes</div>
            <p v-for="(item, index) in estimate.notes" :key="index">
              {{ item.note }}
            </p>
          </template>
          <template v-if="estimate.inputs.terms_and_conditions">
            <div
              class="blank-estimate__info-list-title"
              style="margin-top: 3rem"
            >
              Terms & Conditions
            </div>
            <p>
              {{ estimate.inputs.terms_and_conditions }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RELATED_TO_PROJECT_ID,
  RELATED_TO_SHIPPING_FILE_ID,
} from '@/constants/relatedTo'
import { mapState } from 'vuex'
import IncludesForBlanks from './common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-estimate.less'
import { FORMAT_TAX } from '@/constants/invoices'
import { STATUS_PENDING_SHIPPING } from '@/constants/shippingFiles'

export default {
  name: 'BEstimate',
  components: {
    IncludesForBlanks,
  },
  data() {
    return {
      RELATED_TO_SHIPPING_FILE_ID,
      RELATED_TO_PROJECT_ID,
    }
  },
  props: {
    estimate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
    isTaxForm() {
      return this.estimate.inputs.form_variant === FORMAT_TAX
    },
    containerNumbers() {
      return this.$get(
        this.estimate,
        'inputs.related_to.booking.trucking_information',
        []
      ).reduce((acc, item) => {
        const r = this.$get(item, 'containers', []).reduce((acc2, item2) => {
          if (item2.container_number) {
            acc2.push(item2.container_number)
          }
          return acc2
        }, [])

        return acc.concat(r)
      }, [])
    },
  },
  methods: {
    getStyles() {
      return styles
    },
    getShippingFileStatus(file) {
      const status = this.$get(file, 'shipping_file.status')
      return status ? status.replace(' ', '_') : STATUS_PENDING_SHIPPING
    },
    getClassByStatus(status) {
      switch (status) {
        case 'paid':
          return '_green'
        case 'printed':
          return '_blue'
        case 'unpaid':
          return '_yellow'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/css-blanks/blank-estimate.less';
</style>
