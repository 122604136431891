import { AbilityBuilder, Ability } from '@casl/ability'
import abilityActions from './abilityActions'
import abilitySubjects from './abilitySubjects'

const ability = new Ability()

function generatePermissions(read = [], manage = []) {
  const res = {}
  for (let key in abilitySubjects) {
    if (manage.includes(abilitySubjects[key])) {
      res[abilitySubjects[key]] = abilityActions.manage
    } else if (read.includes(key)) {
      res[abilitySubjects[key]] = abilityActions.read
    } else {
      res[abilitySubjects[key]] = null
    }
  }
  return res
}

const customersPermissions = generatePermissions([
  abilitySubjects.CUSTOMERS_OVERVIEW,
  abilitySubjects.CUSTOMERS_COMMODITIES,
  abilitySubjects.CUSTOMERS_SHIPPING_FILES,
  abilitySubjects.CUSTOMERS_SALES,
  abilitySubjects.CUSTOMERS_SALES_INVOICES,
  abilitySubjects.CUSTOMERS_SALES_ESTIMATES,
  abilitySubjects.SHIPPING_FILES_CONTAINER_CUSTOMER_DOCUMENTS,
  abilitySubjects.SHIPPING_FILES_CONTAINER_CUSTOMER_ESTIMATES,
  abilitySubjects.SHIPPING_FILES_CONTAINER_CUSTOMER_INVOICES,
])

export const employeePermissionsPresets = [
  {
    name: 'Default',
    id: 'default',
    permissions: generatePermissions(
      [
        abilitySubjects.ACCOUNT,
        abilitySubjects.PROJECTS,
        abilitySubjects.SHIPPING_FILES,
      ],
      [
        abilitySubjects.TIME_LOG,
        abilitySubjects.SHIPPING_FILES_FILE_ATTACHMENTS,
      ]
    ),
  },
  {
    name: 'Company manage',
    id: 'company_manage',
    permissions: generatePermissions(
      [abilitySubjects.ACCOUNT],
      [
        abilitySubjects.SETTINGS,
        abilitySubjects.MAILING,
        abilitySubjects.SHIPPING_FILES,
        abilitySubjects.PROJECTS,
        abilitySubjects.TIME_LOG,
        abilitySubjects.SHIPPING_FILES_FILE_ATTACHMENTS,
        abilitySubjects.COMPANY,
      ]
    ),
  },
  {
    name: 'Full',
    id: 'full',
    permissions: generatePermissions([], Object.values(abilitySubjects)),
  },
]

const setAbility = (user, can) => {
  if (user && user.type) {
    switch (user.type) {
      case 'super_admin': {
        can('manage', 'all')
        break
      }
      case 'customer': {
        for (let key in customersPermissions) {
          can(customersPermissions[key], key)
        }
        break
      }
      default: {
        const userPermissions = user.profile.permissions

        //temp
        // userPermissions[abilitySubjects.BILLS] = abilityActions.manage
        // userPermissions[abilitySubjects.SHIPPING_FILES_FILE_BILLS] =
        //   abilityActions.manage
        // userPermissions[abilitySubjects.VENDORS_SUBCONTRACTS] =
        //   abilityActions.manage
        // userPermissions[abilitySubjects.COMPANY] = abilityActions.manage
        // userPermissions[abilitySubjects.SHIPPING_FILES_FILE_ATTACHMENTS] =
        //   abilityActions.manage
        // userPermissions[abilitySubjects.SHIPPING_FILES_FILE_REPORT] =
        //   abilityActions.manage
        //---

        for (let key in abilitySubjects) {
          const subject = abilitySubjects[key]

          if (Object.prototype.hasOwnProperty.call(userPermissions, key)) {
            const action = userPermissions[key]
            if (action) {
              if (
                Object.prototype.hasOwnProperty.call(abilityActions, action)
              ) {
                can(action, subject)
              } else {
                console.warn(
                  `action "${action}" is not exists in the action list`
                )
              }
            } else {
              console.warn(`no access to "${subject}" with action "${action}"`)
            }
          } else {
            console.warn(`not has "${subject}" in user permissions`)
          }
        }
      }
    }
  }
}

const updateAbility = (user) => {
  const { can, rules } = new AbilityBuilder(Ability)
  setAbility(user, can)
  ability.update(rules)
}

export { ability, updateAbility }
