import { isPlainObject } from 'lodash'
import parseErrors from '../helpers/errors/parseErrors'
import scrollToError from '@/helpers/scrollToError'

export default {
  data() {
    return {
      rawErrorsMixin: {},
      overFormErrorMixin: null,
      formErrorsMixin: {},
    }
  },
  methods: {
    getErrorByStringKey(stringKey) {
      if (
        stringKey &&
        typeof stringKey === 'string' &&
        Object.prototype.hasOwnProperty.call(this.rawErrorsMixin, stringKey)
      ) {
        return this.rawErrorsMixin[stringKey]
      }
      return null
    },
    formCheckRequired(inputsObj) {
      if (!inputsObj) inputsObj = this.inputs || this.inputsMixin || undefined

      const customErrors = {}
      for (let key in inputsObj) {
        const isRequired = inputsObj[key].required
        if (!isRequired) continue
        const value = inputsObj[key].value
        let isValid = true

        if (typeof value === 'boolean') {
          continue
        } else if (
          (!value && !Number.isFinite(value)) ||
          (isNaN(value) &&
            ((typeof value === 'string' && !value.trim()) ||
              (Array.isArray(value) && !value.length) ||
              (typeof value === 'object' && !Object.keys(value).length)))
        ) {
          isValid = false
        }

        if (!isValid) {
          customErrors[key] = ['This field is required']
        }
      }

      if (Object.keys(customErrors).length) {
        this.formSetErrorsMixin(inputsObj, customErrors)
        this.$toaster({
          text: 'Fill in all required fields.',
          type: 'ERROR',
        })
        return true
      }
      return false
    },
    formCreateSendDataMixin(inputsObj, options) {
      if (!inputsObj) inputsObj = this.inputs || this.inputsMixin || undefined
      if (!inputsObj) return

      const defaultOptions = {
        saveFullObject: false,
        excludedKeys: [],
      }

      options = Object.assign(defaultOptions, options)

      let newObj = {}
      for (let key in inputsObj) {
        if (
          (options?.excludedKeys?.length &&
            options.excludedKeys.includes(key)) ||
          key === '_id'
        ) {
          continue
        }
        if (key === 'address' && inputsObj[key].value) {
          newObj[key] = Object.assign({}, inputsObj[key].value)
          if (newObj[key].country && newObj[key].country.id) {
            newObj[key].country = newObj[key].country.id
          }
        } else {
          const value = inputsObj[key].value
          let shouldSaveId =
            !options.saveFullObject &&
            !inputsObj[key].saveFullObject &&
            value &&
            value.id

          newObj[key] = shouldSaveId
            ? value.id
            : canSaveValue(value)
            ? value
            : null
        }
      }

      function canSaveValue(value) {
        return value || typeof value === 'boolean' || !isNaN(parseFloat(value))
      }

      if (options.formData) {
        const formData = new FormData()

        for (const key in newObj) {
          let value = newObj[key]

          if (
            value &&
            Object.prototype.hasOwnProperty.call(value, 'with_time')
          ) {
            formData.append(`${key}[with_time]`, value.with_time)
            formData.append(`${key}[value]`, value.value)
            if (value.reminder) {
              formData.append(`${key}[value]`, value.reminder)
            }
          } else {
            value = value === null ? '' : value
            formData.append(key, value)
          }
        }

        return formData
      } else {
        return newObj
      }
    },
    formSetInputsMixin(responseInputs, toInputsObj, useResponse) {
      if (!toInputsObj)
        toInputsObj = this.inputs || this.inputsMixin || undefined
      if (!toInputsObj || !responseInputs) return

      for (let key in useResponse ? responseInputs : toInputsObj) {
        if (key === '_id') {
          continue
        }

        if (!toInputsObj[key]) this.$set(toInputsObj, key, {})

        if (!Object.prototype.hasOwnProperty.call(responseInputs, key)) {
          continue
        }
        const value = responseInputs[key]
        toInputsObj[key].value = (value && value.attributes) || value
      }
    },
    formSetErrorsMixin(inputsObj, errorsObj) {
      if (!inputsObj || !errorsObj) return
      for (let key in errorsObj) {
        let inputKey = key.split('.')[0]
        let inputIndex = key.split('.')[1]

        if (Object.prototype.hasOwnProperty.call(inputsObj, inputKey)) {
          if (inputIndex) {
            const errorObj =
              this.$get(inputsObj, `[${inputKey}].errorObj`, {}) || {}
            const errorArr =
              this.$get(inputsObj, `[${inputKey}].error`, []) || []
            errorObj[inputIndex] = errorsObj[key]
            this.$set(inputsObj[inputKey], 'errorObj', errorObj)
            this.$set(
              inputsObj[inputKey],
              'error',
              errorArr.concat(...errorsObj[key])
            )
          } else {
            this.$set(inputsObj[inputKey], 'error', errorsObj[key])
          }
        }
      }
      scrollToError()
    },
    formSetCustomErrorMixin(error) {
      if (!error) return
      this.overFormErrorMixin = error
    },
    formClearErrorsMixin(inputsObj) {
      this.$set(this, 'overFormErrorMixin', null)
      this.$set(this, 'formErrorsMixin', {})
      this.$set(this, 'rawErrorsMixin', {})
      if (!inputsObj) inputsObj = this.inputs || this.inputsMixin
      if (!inputsObj) return

      for (let key in inputsObj) {
        if (Object.prototype.hasOwnProperty.call(inputsObj[key], 'error')) {
          this.$set(inputsObj[key], 'error', null)
        }
        if (Object.prototype.hasOwnProperty.call(inputsObj[key], 'errorObj')) {
          this.$set(inputsObj[key], 'errorObj', null)
        }
      }
    },
    formClearInputsMixin(inputsObj) {
      if (!inputsObj) inputsObj = this.inputs || this.inputsMixin

      for (let key in inputsObj) {
        this.$set(inputsObj[key], 'value', null)
      }
    },
    errorHandlerMixin(e, inputsObj) {
      if (!inputsObj) inputsObj = this.inputs || null

      if (!e.response || !e.response.data) {
        if (e.status === 413) {
          return (this.overFormErrorMixin = 'File Too Large')
        }

        return (this.overFormErrorMixin = e.message)
      }

      if (e.response.data && e.response.data.errors) {
        if (typeof e.response.data.errors === 'string') {
          return (this.overFormErrorMixin = e.response.data.errors)
        }
        this.rawErrorsMixin = e.response.data.errors
        this.formErrorsMixin = parseErrors(e.response.data.errors)
        if (inputsObj) {
          this.formSetErrorsMixin(inputsObj, e.response.data.errors)
        }
      }

      if (e.response.data && e.response.data.message) {
        return (this.overFormErrorMixin = e.response.data.message)
      }

      return (this.overFormErrorMixin = e.message)
    },
    getErrorsByPathMixin(path) {
      if (typeof path !== 'string') return
      const keys = path.split('.')

      if (!keys.length) return

      return getError(keys, this.formErrorsMixin)
    },
  },
}

function getError(keys, errors) {
  if (!isPlainObject(errors)) return errors

  if (keys.length === 1) {
    return errors[keys[0]]
  } else {
    let key = keys.shift()
    return getError(keys, errors[key])
  }
}
