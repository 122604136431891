import formatTime from '@/helpers/formatTime'
import { formatTimeByMS } from '@/helpers/timeUtils'
import Vue from 'vue'
import formatDate from '@/helpers/formatDate'

Vue.filter('dateFormat', (date, withTime = false, emptyValue = undefined) => {
  return formatDate(date, withTime, emptyValue)
})
Vue.filter('timeFormat', (date, withTime = false, emptyValue = undefined) => {
  return formatTime(date, withTime, emptyValue)
})
Vue.filter('timeFormatByMS', (ms = 0) => {
  return formatTimeByMS(ms)
})
