import { render, staticRenderFns } from "./BInvoice.vue?vue&type=template&id=0230c9bd&scoped=true"
import script from "./BInvoice.vue?vue&type=script&lang=js"
export * from "./BInvoice.vue?vue&type=script&lang=js"
import style0 from "./BInvoice.vue?vue&type=style&index=0&id=0230c9bd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0230c9bd",
  null
  
)

export default component.exports