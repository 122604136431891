var render = function render(_c,_vm){return _c('svg',{class:[_vm.data.class, _vm.data.staticClass],style:([
    _vm.data.style,
    _vm.data.staticStyle,
    { height: _vm.props.height || '', width: _vm.props.width || '' },
  ]),attrs:{"width":_vm.props.width,"height":_vm.props.height,"xmlns":"http://www.w3.org/2000/svg"}},[_c('use',{attrs:{"xlink:href":`${require('@/assets/img/svg/sprite-main.svg')}#${
      _vm.props.name
    }`}})])
}
var staticRenderFns = []

export { render, staticRenderFns }