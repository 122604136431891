import $router from '@/router'
import { axiosApi, setToken } from '@/axios'
import { updateAbility } from '@/services/ability'
import Vue from 'vue'
import {
  customerConfigs,
  vendorConfigs,
  employeeConfigs,
  adminConfigs,
} from '@/constants/personsConfigs'

export default {
  namespaced: true,
  state: {
    pending: false,
    token: null,
    profile: null,
    company_profile: null,
    type: null,
    loaded: false,
    userPromise: null,
    pendingFiles: false,
    logoutPromise: null,
    validate_rules: {},
  },
  getters: {
    isLogged(state) {
      return state.loaded
    },
    isLoggedAsCustomer(state) {
      return String(state.type) === 'customer'
    },
    isLoggedAsAdmin(state) {
      return String(state.type) === 'super_admin'
    },
    isLoggedAsEmployee(state) {
      return String(state.type) === 'employee'
    },
    userName(state) {
      return state.profile
        ? `${state?.profile?.first_name ?? ''} ${
            state?.profile?.last_name ?? ''
          }`.trim()
        : ''
    },
    userProfile(state) {
      return state?.profile ?? null
    },
    userId(state) {
      return state.profile ? String(state.profile.id) : null
    },
    userConfigs(state) {
      switch (state.type) {
        case 'super_admin': {
          return adminConfigs
        }
        case 'customer': {
          return customerConfigs
        }
        case 'vendor': {
          return vendorConfigs
        }
        case 'employee': {
          return employeeConfigs
        }
        default: {
          return {}
        }
      }
    },
    isShowMainMenu(state) {
      //hide main menu for types:
      return !['customer', 'vendor'].includes(state?.type ?? '')
    },
    maxFileSize(state) {
      return state.validate_rules.upload_max_filesize || 52428800
    },
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
    addFiles(state, files) {
      state.profile.files = [].concat(files, state.profile.files)
    },
    deleteFile(state, index) {
      state.profile.files.splice(index, 1)
    },
    changeFile(state, { index, file }) {
      Vue.set(state.profile.files, index, file)
    },
  },
  actions: {
    init({ commit, dispatch }) {
      if (window.location.href.includes('new-password')) {
        return dispatch('reset')
      }

      const token = getTokenFromLocalstorage()
      if (!token) return

      commit('setProperty', ['token', token])
      dispatch('setTokenToAllConfigs')
      dispatch('loadUser').catch(() => {})
    },
    setTokenToAllConfigs({ state }) {
      const token = state.token
      setToken(token)
      if (token) {
        setTokenToLocalstorage(token)
      } else {
        removeTokenFromLocalStorage()
      }
    },
    setUserData({ commit }, data) {
      commit('setProperty', [
        'profile',
        {
          ...data.item.attributes,
        },
      ])
      commit('setProperty', ['company_profile', data.company_profile])
      commit('setProperty', ['validate_rules', data.validate_rules || {}])
      commit('setProperty', ['type', data.item.type])
      commit('setProperty', ['loaded', true])
    },
    loadUser({ state, commit, dispatch }, clear = false) {
      if (clear) {
        commit('setProperty', ['loaded', false])
        commit('setProperty', ['promiseUser', null])
        commit('setProperty', ['pending', false])
      } else {
        if (state.loaded) {
          commit('setProperty', ['pending', false])
          if (state.userPromise) {
            commit('setProperty', ['userPromise', null])
          }
          return Promise.resolve(state)
        }
        if (state.pending && state.userPromise) {
          return state.userPromise
        }
      }

      commit('setProperty', ['pending', true])

      const promise = axiosApi
        .get('/office')
        .then(({ data }) => {
          dispatch('setUserData', data)
          return state
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
          commit('setProperty', ['userPromise', null])
          dispatch('onUserLoad')
        })

      commit('setProperty', ['userPromise', promise])
      return promise
    },
    async passwordReset({ state, commit, dispatch }, sendData) {
      if (state.loaded) {
        await dispatch('logout').catch(() => {})
      }
      commit('setProperty', ['pending', true])
      return axiosApi
        .post('/reset-password', sendData)
        .then(({ data }) => {
          dispatch('afterAuth', data)
          return data
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
          dispatch('onUserLoad')
        })
    },
    async login({ state, commit, dispatch }, sendData) {
      if (state.loaded) {
        await dispatch('logout').catch(() => {})
      }
      commit('setProperty', ['pending', true])
      return axiosApi
        .post('/login', sendData)
        .then(({ data }) => {
          if (data.status_code !== 'need_confirm_code') {
            dispatch('afterAuth', data)
          }
          return data
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
          dispatch('onUserLoad')
        })
    },
    afterAuth({ commit, dispatch }, data) {
      const token = `${data.auth.token_type} ${data.auth.access_token}`
      commit('setProperty', ['token', token])
      dispatch('setTokenToAllConfigs')

      dispatch('setUserData', data)
    },
    onUserLoad({ state }) {
      if (state.type) {
        updateAbility(state)
        console.info(`loaded user, type - ${state.type}`)
      }
    },
    onLogout({ dispatch }) {
      const from = $router.currentRoute.fullPath
      return $router
        .push({
          name: 'auth',
          query: {
            redirect: from,
          },
        })
        .catch(() => {})
        .finally(() => {
          dispatch('reset')
        })
    },
    reset({ dispatch, commit }) {
      commit('setProperty', ['token', null])
      commit('setProperty', ['profile', null])
      commit('setProperty', ['company_profile', null])
      commit('setProperty', ['type', null])
      commit('setProperty', ['loaded', false])
      commit('setProperty', ['promiseUser', null])
      commit('setProperty', ['logoutPromise', null])
      commit('setProperty', ['pending', false])
      dispatch('setTokenToAllConfigs')
      updateAbility()
    },
    logout({ commit, dispatch, state }) {
      commit('setProperty', ['pending', true])

      if (['auth', 'auth-new-password'].includes($router.currentRoute.name)) {
        return dispatch('reset')
      }

      if (state.logoutPromise) {
        return state.logoutPromise
      }

      const logoutPromise = axiosApi.get('/logout').finally(() => {
        return dispatch('onLogout')
      })
      commit('setProperty', ['logoutPromise', logoutPromise])
      return logoutPromise
    },
    uploadFiles({ state, getters, commit }, { files }) {
      if (!state.profile.id) return
      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
      }

      commit('setProperty', ['pendingFiles', true])

      axiosApi
        .post(
          `${getters.userConfigs.httpBaseLink}/${state.profile.id}/files/`,
          formData
        )
        .then(({ data }) => {
          commit(
            'addFiles',
            data.map((item) => item.attributes)
          )
        })
        .finally(() => {
          commit('setProperty', ['pendingFiles', false])
        })
    },
    changeName({ state, getters, commit }, { index, name }) {
      const file = state.profile.files[index]

      const sendData = {
        name,
      }

      commit('setProperty', ['pendingFiles', true])
      axiosApi
        .put(
          `${getters.userConfigs.httpBaseLink}/${state.profile.id}/files/${file.id}/change_name`,
          sendData
        )
        .then(({ data }) => {
          commit('changeFile', { index, file: data.item.attributes })
        })
        .finally(() => {
          commit('setProperty', ['pendingFiles', false])
        })
    },
    deleteFile({ state, getters, commit }, { index }) {
      const file = state.profile.files[index]
      commit('setProperty', ['pendingFiles', true])
      axiosApi
        .delete(
          `${getters.userConfigs.httpBaseLink}/${state.profile.id}/files/${file.id}`
        )
        .then(() => {
          commit('deleteFile', index)
        })
        .finally(() => {
          commit('setProperty', ['pendingFiles', false])
        })
    },
  },
}

function setTokenToLocalstorage(token) {
  window.localStorage.setItem('token', token)
}

function getTokenFromLocalstorage() {
  return window.localStorage.getItem('token') || null
}

function removeTokenFromLocalStorage() {
  window.localStorage.removeItem('token')
}
