export const keys = [
  'name',
  'listName',
  'storeLink',
  'httpBaseLink',
  'editModalLink',
  'newModalLink',
  'personRouteName',
  'personsListRouteName',
  'personRouteIdParamName',
  'deleteTitle',
  'modalsPrefix',
  'noItemsText',
]

export const customerConfigs = {
  name: 'Customer',
  listName: 'Customers',
  storeLink: 'customers',
  httpBaseLink: 'customers',
  editModalLink: 'edit-customer',
  newModalLink: 'new-customer',
  personRouteName: 'one-customer',
  personsListRouteName: 'customers',
  personRouteIdParamName: 'customerId',
  deleteTitle: 'Delete customer?',
  modalsPrefix: 'customer',
  noItemsText: 'No customers',
}

export const vendorConfigs = {
  name: 'Vendor',
  listName: 'Persons',
  storeLink: 'vendors',
  httpBaseLink: 'vendors',
  editModalLink: 'edit-vendor',
  newModalLink: 'new-vendor',
  personRouteName: 'one-vendor',
  personsListRouteName: 'vendors',
  personRouteIdParamName: 'vendorId',
  deleteTitle: 'Delete vendor?',
  modalsPrefix: 'vendor',
  noItemsText: 'No vendors',
}

export const employeeConfigs = {
  name: 'Employee',
  listName: 'Employees',
  storeLink: 'employees',
  httpBaseLink: 'employees',
  editModalLink: 'edit-employee',
  newModalLink: 'new-employee',
  personRouteName: 'company-employee',
  personsListRouteName: 'company-employees',
  personRouteIdParamName: 'employeeId',
  deleteTitle: 'Delete employee?',
  modalsPrefix: 'employee',
  noItemsText: 'No employees',
}
export const paymentAccountConfigs = {
  name: 'Payment account',
  listName: 'Payment accounts',
  storeLink: 'paymentAccounts',
  httpBaseLink: 'payment-accounts',
  editModalLink: 'edit-payment-account',
  newModalLink: 'new-payment-account',
  personRouteName: 'banking-payment-account',
  personsListRouteName: 'banking-payment-accounts',
  personRouteIdParamName: 'paymentAccountId',
  deleteTitle: 'Delete payment account?',
  modalsPrefix: 'payment-account',
  noItemsText: 'No accounts',
}

export const adminConfigs = {
  name: 'Admin',
  listName: 'Admins',
}
