import projectsRoutes from '@/router/routes/projectsRoutes'
import { getRoutes as getSettingsRoutes } from '@/router/routes/settingsRoutes'
import profileRoutes from '@/router/routes/companyProfileRoutes'
import accountRoutes from '@/router/routes/accountRoutes'
import shippingFileRoutes from '@/router/routes/shippingFileRoutes'
import bankingRoutes from '@/router/routes/bankingRoutes'
import customersRoutes from '@/router/routes/customersRoutes'
import vendorsRoutes from '@/router/routes/vendorsRoutes'
import salesRoutes from '@/router/routes/salesRoutes'
import ratesRoutes from '@/router/routes/ratesRoutes'

import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'

const routes = [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/Auth'),
    children: [
      {
        path: '',
        name: 'auth',
        component: () =>
          import(
            /* webpackChunkName: "AuthMainForm" */ '@/views/Auth/AuthMainForm'
          ),
        meta: {
          title: 'Login',
        },
      },
      {
        path: 'new-password',
        name: 'auth-new-password',
        component: () =>
          import(
            /* webpackChunkName: "AuthNewPassword" */ '@/views/Auth/AuthNewPassword'
          ),
        beforeEnter(to, from, next) {
          if (!to.query.token) {
            next({
              name: 'auth',
            })
          } else {
            next()
          }
        },
        meta: {
          title: 'Create new password',
        },
      },
      {
        path: 'forgot-password',
        name: 'auth-forgot-password',
        component: () =>
          import(
            /* webpackChunkName: "AuthForgotPassword" */ '@/views/Auth/AuthForgotPassword'
          ),
        meta: {
          title: 'Reset password',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Main" */ '@/views/Main'),
    children: [
      {
        path: '',
        name: 'main',
        component: () =>
          import(/* webpackChunkName: "BlankView" */ '@/views/Main/BlankView'),
      },
      {
        path: 'calendar',
        redirect: { name: 'dashboard', params: { rangeType: 'month' } },
        meta: {
          permission: [abilityActions.read, abilitySubjects.CALENDAR],
          title: 'Calendar',
        },
      },
      {
        path: 'calendar/:rangeType?',
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ '@/views/Main/Dashboard'),
        name: 'dashboard',
        meta: {
          permission: [abilityActions.read, abilitySubjects.CALENDAR],
          title: 'Calendar',
        },
      },
      {
        path: 'expenses',
        name: 'expenses',
        component: () =>
          import(/* webpackChunkName: "Expenses" */ '@/views/Main/Expenses'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.EXPENSES],
          title: 'Expenses',
        },
        children: [
          {
            path: ':expenseId',
            name: 'expense',
            component: () =>
              import(
                /* webpackChunkName: "ExpensePage" */ '@/views/Main/Expenses/ExpensePage'
              ),
            meta: {
              permission: [abilityActions.read, abilitySubjects.EXPENSES],
            },
          },
        ],
      },
      {
        path: 'bills',
        name: 'bills',
        component: () =>
          import(/* webpackChunkName: "Bills" */ '@/views/Main/Bills'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.BILLS],
          title: 'Bills',
        },
        children: [
          {
            path: ':billId',
            name: 'bill',
            component: () =>
              import(
                /* webpackChunkName: "BillPage" */ '@/views/Main/Bills/BillPage'
              ),
            meta: {
              permission: [abilityActions.read, abilitySubjects.BILLS],
            },
          },
        ],
      },
      {
        path: 'commodities/new-commodity',
        name: 'new-commodity',
        component: () =>
          import(
            /* webpackChunkName: "NewCommodity" */ '@/views/Main/Commodities/NewCommodity'
          ),
        meta: {
          permission: [abilityActions.manage, abilitySubjects.COMMODITIES],
          title: 'New Commodity',
        },
      },
      {
        path: 'commodities/:commodityId(\\d+)',
        name: 'commodity',
        component: () =>
          import(
            /* webpackChunkName: "Commodity" */ '@/views/Main/Commodities/Commodity'
          ),
        meta: {
          permission: [abilityActions.read, abilitySubjects.COMMODITIES],
          title: 'Commodities',
        },
      },
      {
        path: 'commodities/:status',
        name: 'commodities-with-status',
        component: () =>
          import(
            /* webpackChunkName: "Commodities" */ '@/views/Main/Commodities'
          ),
        meta: {
          permission: [abilityActions.read, abilitySubjects.COMMODITIES],
          title: 'Commodities',
        },
      },
      {
        path: 'commodities',
        name: 'commodities',
        component: () =>
          import(
            /* webpackChunkName: "Commodities" */ '@/views/Main/Commodities'
          ),
        meta: {
          permission: [abilityActions.read, abilitySubjects.COMMODITIES],
          title: 'Commodities',
        },
      },
      {
        path: 'bookings',
        name: 'bookings',
        component: () =>
          import(/* webpackChunkName: "Bookings" */ '@/views/Main/Bookings'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.BOOKINGS],
          title: 'Bookings',
        },
        children: [
          {
            path: ':bookingId',
            name: 'booking',
            component: () =>
              import(
                /* webpackChunkName: "BookingPage" */ '@/views/Main/Bookings/BookingPage'
              ),
            meta: {
              permission: [abilityActions.read, abilitySubjects.BOOKINGS],
            },
          },
        ],
      },
      {
        path: 'aes-itn-manager',
        name: 'aes-itn-manager',
        component: () =>
          import(
            /* webpackChunkName: "AESITNManager" */ '@/views/Main/AESITNManager'
          ),
        meta: {
          permission: [abilityActions.read, abilitySubjects.AES_ITN_MANAGER],
          title: 'AES/ITN Manager',
        },
      },
      {
        path: 'inventory',
        name: 'inventory',
        component: () =>
          import(/* webpackChunkName: "Inventory" */ '@/views/Main/Inventory'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.INVENTORY],
          title: 'Inventory',
        },
      },
      {
        path: 'reports/:slug?',
        name: 'reports',
        component: () =>
          import(/* webpackChunkName: "Reports" */ '@/views/Main/Reports'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.REPORTS],
          title: 'Reports',
        },
      },
      {
        path: 'time-log',
        name: 'time-log',
        component: () =>
          import(/* webpackChunkName: "TimeLog" */ '@/views/Main/TimeLog'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.TIME_LOG],
          title: 'Time Log',
        },
      },
      {
        path: 'settings',
        component: () =>
          import(/* webpackChunkName: "settings" */ '@/views/Main/Settings'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.SETTINGS],
          title: 'Settings',
        },
        children: [
          {
            path: '',
            name: 'lists',
            component: () =>
              import(
                /* webpackChunkName: "Settings_Default" */ '@/views/Main/Settings/Default'
              ),
            meta: {
              permission: [abilityActions.read, abilitySubjects.SETTINGS],
            },
          },
          ...getSettingsRoutes(),
        ],
      },
      {
        path: 'time-kiosk',
        name: 'time-kiosk',
        component: () =>
          import(/* webpackChunkName: "TimeKiosk" */ '@/views/Main/TimeKiosk'),
        meta: {
          permission: [abilityActions.read, abilitySubjects.TIME_KIOSK],
          title: 'Time Kiosk',
        },
      },
      ...projectsRoutes,
      ...customersRoutes,
      ...bankingRoutes,
      ...vendorsRoutes,
      ...salesRoutes,
      ...ratesRoutes,
      ...shippingFileRoutes,
      ...profileRoutes,
      ...accountRoutes,
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: () => import(/* webpackChunkName: "E404" */ '@/views/E404'),
    meta: {
      title: 'Page not found',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "E403" */ '@/views/E403'),
    meta: {
      title: 'Forbidden',
    },
  },
]

if (process.env.NODE_ENV !== 'production') {
  routes[1].children.unshift({
    path: 'guide',
    name: 'guide',
    component: () =>
      import(/* webpackChunkName: "Guide" */ '@/views/Main/Guide'),
  })
}

export default routes
