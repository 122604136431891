import { getDate } from '@/helpers/formatDate'
import { TIME_FORMAT } from '@/constants/date'

export default function (date, withTime = false, emptyValue = undefined) {
  let dateMoment = getDate(date, withTime)
  if (!dateMoment) return emptyValue

  dateMoment.local()
  return dateMoment.format(TIME_FORMAT)
}
